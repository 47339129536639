import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'
import useLocalStorage from 'lib/useLocalStorage'

import { useState, useEffect, useRef } from 'react'

import Select from 'react-select'
import Layout from 'components/layout'

import GeoJSON from 'geojson'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = 'pk.eyJ1Ijoib3pwb28iLCJhIjoicmRMbmhHayJ9.2Ej3FH8CTV9bvARvCRvDiQ'

const lifecycleStages = [
  {
    value: 'customer',
    label: 'Customer'
  },{
    value: 'lead',
    label: 'Lead'
  },{
    value: 'opportunity',
    label: 'Opportunity'
  },{
    value: 'subscriber',
    label: 'Subscriber'
  },{
    value: 'salesqualifiedlead',
    label: 'Sales Qualified Lead'
  }
]

export default function Index() {
  useAuth()

  const [data, setData] = useState(false)
  const [geoJSON, setGeoJSON] = useState(false)
  const [filter, setFilter] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'filters-maps', lifecycleStages[0])

  const mapContainer = useRef(null)
  const map = useRef(null)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasPainted, setHasPainted] = useState(false)
  const [lat, setLat] = useState(39.0997)
  const [lng, setLng] = useState(-94.5786)
  const [zoom, setZoom] = useState(11)

  useEffect(() => {
    async function fetchData() {
      const doc = await db.collection('storage').doc('contacts').get()
      const url = doc.data().contactsFullJSON.url
      fetch(url)
      .then(response => {
        return response.json()
      })
      .then(json => {
        setData(json)
      })
    }
    fetchData()

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [lng, lat],
      zoom: zoom
    })
    map.current.on('load', async function() {
      setHasLoaded(true)
    })
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setZoom(map.current.getZoom().toFixed(2))
    })

    return () => {
      setHasPainted(false)
    }
  }, [])

  useEffect(() => {
    if(hasLoaded && geoJSON && !hasPainted) {
      map.current.addSource('customers', {
        type: 'geojson',
        data: geoJSON
      })

      map.current.addLayer({
        id: 'customers-heat',
        type: 'heatmap',
        source: 'customers',
        maxzoom: 15,
        paint: {
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [1, 0],
              [62, 1]
            ]
          },
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [11, 1],
              [15, 3]
            ]
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(236,222,239,0)',
            0.2, 'rgb(208,209,230)',
            0.4, 'rgb(166,189,219)',
            0.6, 'rgb(103,169,207)',
            0.8, 'rgb(28,144,153)'
          ],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [10, 20],
              [20, 30]
            ]
          },
          // decrease opacity to transition into the circle layer
          'heatmap-opacity': {
            default: 1,
            stops: [
              [14, 1],
              [15, 0]
            ]
          },
        }
      }, 'waterway-label')

      map.current.addLayer({
        id: 'customers-point',
        type: 'circle',
        source: 'customers',
        minzoom: 14,
        paint: {
          // increase the radius of the circle as the zoom level and dbh value increases
          'circle-radius': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50],
            ]
          },
          'circle-color': [
            'match',
            ['get', 'lifecycleStage'],
            'Customer',
            '#fbb03b',
            'Lead',
            '#223b53',
            'Opportunity',
            '#e55e5e',
            'Subscriber',
            '#3bb2d0',
            /* other */ '#ccc'
          ],
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-opacity': {
            stops: [
              [14, 0],
              [15, 1]
            ]
          }
        }
      }, 'waterway-label')

      // map.current.addLayer({
      //   'id': 'lifecycleStage',
      //   'type': 'circle',
      //   'source': 'customers',
      //   'paint': {
      //     // make circles larger as the user zooms from z12 to z22
      //     'circle-radius': {
      //       'base': 2,
      //       'stops': [
      //         [12, 2],
      //         [22, 180]
      //       ]
      //     },
      //     // color circles by lifecycleStage, using a match expression
      //     // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
      //     'circle-color': [
      //       'match',
      //       ['get', 'lifecycleStage'],
      //       'Customer',
      //       '#fbb03b',
      //       'Lead',
      //       '#223b53',
      //       'Opportunity',
      //       '#e55e5e',
      //       'Subscriber',
      //       '#3bb2d0',
      //       /* other */ '#ccc'
      //     ]
      //   }
      // }, 'waterway-label')

      map.current.on('click', 'customers-point', function(e) {
        console.log(e.features[0])
        new mapboxgl.Popup()
        .setLngLat(e.features[0].geometry.coordinates)
        .setHTML(`
          <b>${e.features[0].properties.firstName} ${e.features[0].properties.lastName}</b><br />
          ${e.features[0].properties.address}<br /><br />
          ${e.features[0].properties.email}<br />
          ${e.features[0].properties.phone}<br />
          ${e.features[0].properties.lifecycleStage}
        `)
        .addTo(map.current)
      })
      setHasPainted(true)
    }
  }, [hasLoaded, geoJSON])

  useEffect(() => {
    if(data) setGeoJSON(GeoJSON.parse(data.contacts, { Point: ['lat', 'lng'] }))
  }, [data])

  useEffect(() => {
    if(hasPainted) {
      if(filter) {
        map.current.setFilter('customers-heat', ['==', ['get', 'lifecycleStage'], filter.label])
      } else {
        map.current.setFilter('customers-heat', null)
      }
    }
  }, [hasPainted, filter])

  function setView() {

  }

  return (
    <Layout>
      <main>
        <div className='relative z-10 px-6 pt-32 w-full max-w-4xl m-auto'>
          <div className='flex w-full justify-between'>
            <div className='flex items-center'>
              <Select
                className='react-select-container-sm w-80'
                classNamePrefix='react-select'
                placeholder='Filter'
                value={filter}
                onChange={(e) => setFilter(e)}
                options={lifecycleStages}
              />
              {filter &&
                <button className='ml-2 btn-circle-gray px-2 hover:text-primary transition duration-150 focus:outline-none' onClick={() => setFilter(null)}>
                  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>
              }
            </div>
            <div className='flex'>
              <button className='btn-circle-gray mr-2' onClick={() => setView('list')}>
                <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6h16M4 10h16M4 14h16M4 18h16' />
                </svg>
              </button>
              <button className='btn-circle-gray' onClick={() => setView('grid')}>
                <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z' />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className='p-12 fixed inset-0 z-0'>
          <div className='text-black h-full w-full bg-gray-900 rounded-xl overflow-hidden'>
            <div ref={mapContainer} className='w-full h-full z-0 rounded-xl overflow-hidden' />
          </div>
          <div className='absolute top-0 left-0 m-4 leading-none bg-gray-900 px-4 py-2 rounded-xl text-sm'>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </div>
      </main>
    </Layout>
  )
}
