import { useAuth } from 'lib/firebase/use-auth'
import { db, deleteField } from 'lib/firebase/firebase'
import useLocalStorage from 'lib/useLocalStorage'

import { useState, useEffect } from 'react'

import Select from 'react-select'
import Modal from 'react-modal'
import Layout from 'components/layout'

import debounce from 'lodash.debounce'

const lifecycleStages = [
  {
    value: 'customer',
    label: 'Customer'
  },{
    value: 'lead',
    label: 'Lead'
  },{
    value: 'opportunity',
    label: 'Opportunity'
  },{
    value: 'subscriber',
    label: 'Subscriber'
  },{
    value: 'salesqualifiedlead',
    label: 'Sales Qualified Lead'
  }
]

export default function Index() {
  useAuth()

  const [filter, setFilter] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'filters-contacts', lifecycleStages[0])
  const [view, setView] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'views-contacts', 'list')

  const [contacts, setContacts] = useState([])

  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [currentDoc, setCurrentDoc] = useState(false)

  useEffect(() => {
    Modal.setAppElement('body')
  }, [])

  useEffect(() => {
    let unsubscribe = false
    if(filter) {
      // with filter
      unsubscribe = db.collection('contacts')
      .where('lifecycleStage.value', '==', filter.value)
      .orderBy('firstName', 'asc')
      .limit(21)
      .onSnapshot(snapshot => {
        const contacts = []
        snapshot.forEach(doc => contacts.push(doc))
        setContacts(contacts)
      })
    } else {
      // without filter
      unsubscribe = db.collection('contacts')
      .orderBy('firstName', 'asc')
      .limit(21)
      .onSnapshot(snapshot => {
        const contacts = []
        snapshot.forEach(doc => contacts.push(doc))
        setContacts(contacts)
      })
    }

    return () => unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <Layout>
      <main>
        {/* <ul className='fixed z-10 bottom-0 right-0 p-6'>
          <button className='btn-circle-primary-lg' onClick={() => setShowAdd(true)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
            </svg>
          </button>
        </ul> */}
        {view === 'list' && <List contacts={contacts} setShowEdit={setShowEdit} setCurrentDoc={setCurrentDoc} filter={filter} setFilter={setFilter} setView={setView} />}
        {view === 'grid' && <Grid contacts={contacts} setShowEdit={setShowEdit} setCurrentDoc={setCurrentDoc} filter={filter} setFilter={setFilter} setView={setView} />}
        <AddContact isOpen={showAdd} close={() => setShowAdd(false)} />
        <EditContact isOpen={showEdit} doc={currentDoc} close={() => setShowEdit(false)} />
      </main>
    </Layout>
  )
}

function Filter({ filter, setFilter, setView }) {
  return (
    <div className='mb-6 flex w-full justify-between'>
      <div className='flex'>
        <Select
          className='react-select-container-sm w-80'
          classNamePrefix='react-select'
          placeholder='Filter'
          value={filter}
          onChange={(e) => setFilter(e)}
          options={lifecycleStages}
        />
        {filter &&
          <button className='px-2 text-gray-600 hover:text-primary transition duration-150 focus:outline-none' onClick={() => setFilter(null)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        }
      </div>
      <div className='flex'>
        <button className='btn-circle-gray mr-2' onClick={() => setView('list')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6h16M4 10h16M4 14h16M4 18h16' />
          </svg>
        </button>
        <button className='btn-circle-gray' onClick={() => setView('grid')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z' />
          </svg>
        </button>
      </div>
    </div>
  )
}

function List({ contacts, setShowEdit, setCurrentDoc, filter, setFilter, setView }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} />
      <ul className='divide-y-2 divide-gray-900'>
        {contacts.map((doc) => {
          const zoom = 19
          const location = doc.data().location ? doc.data().location.lat+','+doc.data().location.lng : false
          const mapSrc = 'https://maps.googleapis.com/maps/api/staticmap?center=' + location + '&markers=' + location + '&zoom=' + zoom + '&size=400x225&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY
          return (
            <li className='fade-in' key={'list-'+doc.id}>
              <button
                onClick={() => {
                  setShowEdit(true)
                  setCurrentDoc(doc)
                }}
                className='group py-2 block w-full text-left flex items-center focus:outline-none'>
                <img alt='Map' src={mapSrc} className='object-cover mr-4 h-12 w-12 rounded-full overflow-hidden' />
                <span className='pr-4 group-hover:text-primary transition duration-150 flex-1 truncate'>{doc.data().firstName} {doc.data().lastName}</span>
                <span className='flex-1 truncate text-gray-600 text-right'>{doc.data().address}</span>
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function Grid({ contacts, setShowEdit, setCurrentDoc, filter, setFilter, setView }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} />
      <ul className='grid grid-cols-3 gap-6'>
        {contacts.map((doc) => {
          const zoom = 19
          const location = doc.data().location ? doc.data().location.lat+','+doc.data().location.lng : false
          const mapSrc = 'https://maps.googleapis.com/maps/api/staticmap?center=' + location + '&markers=' + location + '&zoom=' + zoom + '&size=400x225&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY
          return (
            <li className='fade-in' key={'grid-'+doc.id}>
              <button
                onClick={() => {
                  setShowEdit(true)
                  setCurrentDoc(doc)
                }}
                className='leading-tight border-2 border-gray-900 hover:border-primary transition duration-150 rounded-xl overflow-hidden block w-full text-left focus:outline-none'>
                <img alt={doc.data().title + ' featured image.'} src={mapSrc} />
                <span className='block px-4 pt-2 truncate'>{doc.data().firstName} {doc.data().lastName}</span>
                <span className='block px-4 pb-2 truncate text-gray-600'>{doc.data().address || 'No Address.'}</span>
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function AddContact({ isOpen, close }) {
  const [content, setContent] = useState(false)

  const onChange = (e) => {
    const { value, name } = e.target
    setContent(prevState => {
      return ({
        ...prevState,
        [name]: (value === null || value === '') ? deleteField : value
      })
    })
  }

  const onSave = async () => {
    if(content && 'name' in content && 'category' in content) {
      await db.collection('contacts').add({ createdAt: new Date(), ...content })
      close()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      className='m-auto focus:outline-none w-full max-w-md'
      overlayClassName='bg-black top-0 left-0 fixed z-50 h-screen w-screen flex'>
      <div>
        <input
          className='input mb-2'
          type='text'
          name='name'
          placeholder='Name'
          defaultValue={content.name}
          onChange={(e) => onChange(e)} />
        <input
          className='input mb-2'
          type='text'
          name='location'
          placeholder='Location'
          defaultValue={content.location}
          onChange={(e) => onChange(e)} />
        <input
          className='input mb-2'
          type='text'
          name='website'
          placeholder='Website'
          defaultValue={content.website}
          onChange={(e) => onChange(e)} />
        <Select
          className='react-select-container mb-2'
          classNamePrefix='react-select'
          placeholder='Category'
          defaultValue={content.category}
          onChange={(e) => onChange({ target: { name:'category', value: e } })}
          options={lifecycleStages}
        />
        <button
          className='block btn-gray m-auto'
          onClick={() => onSave()}>
          Create
        </button>
      </div>
      <button
        className='btn-gray fixed top-0 right-0 m-6'
        onClick={close}>
        Close
      </button>
    </Modal>
  )
}

function EditContact({ doc, isOpen, close }) {
  const [content, setContent] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    if(doc) {
      setContent(doc.data())
    } else {
      setContent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc])

  useEffect(() => {
    if(content && hasChanged) onSave(content, doc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const onChange = (e) => {
    setHasChanged(true)
    const { value, name } = e.target
    setContent(prevState => {
      return ({
        ...prevState,
        [name]: (value === null || value === '') ? deleteField : value
      })
    })
  }

  const onSave = debounce((content, doc) => {
    db.collection('contacts').doc(doc.id).update(content)
    setHasChanged(false)
  }, 1400)

  const onRemove = async () => {
    await db.collection('contacts').doc(doc.id).delete()
    close()
  }

  const zoom = 19
  const location = content.location ? content.location.lat+','+content.location.lng : false
  const mapSrc = 'https://maps.googleapis.com/maps/api/staticmap?center=' + location + '&markers=' + location + '&zoom=' + zoom + '&size=400x225&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY

  return (
    <Modal
      isOpen={isOpen}
      className='m-auto focus:outline-none w-full max-w-sm'
      overlayClassName='bg-black top-0 left-0 fixed z-50 h-screen w-screen flex'>
      {content &&
        <div>
          <img alt='Map' className='rounded-xl mb-2' src={mapSrc} />
          <input
            className='input mb-2'
            type='text'
            name='firstName'
            placeholder='First Name'
            defaultValue={content.firstName}
            onChange={(e) => onChange(e)} />
          <input
            className='input mb-2'
            type='text'
            name='lastName'
            placeholder='Last Name'
            defaultValue={content.lastName}
            onChange={(e) => onChange(e)} />
          <input
            className='input mb-2'
            type='text'
            name='address'
            placeholder='Address'
            defaultValue={content.address}
            onChange={(e) => onChange(e)} />
          <input
            className='input mb-2'
            type='text'
            name='city'
            placeholder='City'
            defaultValue={content.city}
            onChange={(e) => onChange(e)} />
          <input
            className='input mb-2'
            type='text'
            name='state'
            placeholder='State'
            defaultValue={content.state}
            onChange={(e) => onChange(e)} />
          <Select
            className='react-select-container mb-2'
            classNamePrefix='react-select'
            placeholder='Lifecycle Stage'
            defaultValue={content.lifecycleStage}
            onChange={(e) => onChange({ target: { name:'lifecycleStage', value: e } })}
            options={lifecycleStages}
          />
          {hasChanged &&
            <div className='mb-6 p-4 text-center rounded-xl border-2 border-gray-900'>
              Saving changes...
            </div>
          }
          <button
            className='block btn-gray m-auto'
            onClick={() => onRemove()}>
            Delete
          </button>
        </div>
      }
      <button
        className='btn-gray fixed top-0 right-0 m-6'
        onClick={close}>
        Close
      </button>
    </Modal>
  )
}
