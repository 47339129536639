import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import SignIn from 'pages/auth/sign-in'
import Dashboard from 'pages/dashboard/index'
import Contacts from 'pages/contacts/index'
import Maps from 'pages/maps/index'
import Materials from 'pages/materials/index'
import Projects from 'pages/projects/index'

export default function Index() {
  return (
    <Router>
      <Switch>
        <Route exact path='/signin'>
          <SignIn />
        </Route>
        <Route exact path='/'>
          <Dashboard />
        </Route>
        <Route exact path='/contacts'>
          <Contacts />
        </Route>
        <Route exact path='/maps'>
          <Maps />
        </Route>
        <Route exact path='/materials'>
          <Materials />
        </Route>
        <Route exact path='/projects'>
          <Projects />
        </Route>
      </Switch>
    </Router>
  )
}
