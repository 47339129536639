import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { auth, db } from 'lib/firebase/firebase'

export function useAuth(props) {
  const history = useHistory()
  const location = useLocation()
  const [user, setUser] = useState(false)

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(user => {
      if(user) {
        // setUser(user)
        // if(location.pathname === '/signin') history.push('/')
        db.collection('users').doc(user.uid)
        .onSnapshot(doc => {
          setUser({ ...doc.data(), id: user.uid })
          if(location.pathname === '/signin') history.push('/')
        })
      } else {
        history.push('/signin')
      }
    })
    return () => unsub()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return user
}

export function signOut() {
  auth.signOut()
}

export function signIn(email, password) {
  return auth.signInWithEmailAndPassword(email, password)
  .then(() => { return false }).catch(error => { return error })
}
