import { firebase, storage } from 'lib/firebase/firebase'

export default function useStorage() {

  const uploadImage = (file, name) => {
    return new Promise((resolve, reject) => {
      const metadata = { contentType: 'image/jpeg' }
      const path = name
      const uploadTask = storage.ref().child(path).put(file, metadata)
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
          default:
            break
        }
      },
      (error) => { reject(error) },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL()
        .then((url) => {
          resolve({ url, path })
        })
      })
    })
  }

  const deleteImage = (file) => {
    // file = 'images/desert.jpg'
    var desertRef = storage.ref().child(file)
    return desertRef.delete()
    .then(() => {
      return true
    })
    .catch((error) => {
      return { error }
    })
  }

  return { uploadImage, deleteImage }
}
