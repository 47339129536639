import useStorage from 'lib/useStorage'

import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export function UploadImage({ data, fileName, onChange }) {
  const storage = useStorage()
  const storagePath = 'images/materials/' + data.id + '/' + fileName + '.jpg'

  const [isUploading, setIsUploading] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    upload(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop, accept: 'image/*'})

  function upload(file) {
    setIsUploading(true)
    storage.uploadImage(file, storagePath)
    .then(image => {
      onChange({ target: { name: fileName, value: image } })
      setIsUploading(false)
    })
    .catch(error => {
      setIsUploading(false)
    })
  }

  function remove() {
    storage.deleteImage(storagePath)
    .then(() => {
      onChange({ target: { name: fileName, value: null } })
    })
  }

  if(data[fileName] && 'url' in data[fileName]) {
    return (
      <div>
        <img
          className='border-2 border-gray-900 rounded-xl overflow-hidden mb-4'
          alt='Feature'
          src={data[fileName].url} />
        <button
          className='m-auto mb-4 btn-circle-gray text-gray-700 hover:text-primary hover:bg-gray-900'
          onClick={() => remove()}>
          <svg className='m-auto w-5 h-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
          </svg>
        </button>
      </div>
    )
  } else {
    return (
      <div className='mb-2 transition duration-200 relative cursor-pointer bg-gray-900 border-2 border-gray-900 hover:border-primary rounded-xl focus:outline-none text-sm leading-tight' {...getRootProps()}>
        <div className='flex items-center'>
          <div className='p-3'>
            {!isUploading && <input {...getInputProps()} />}
            <div className='flex items-center'>
              <svg width='40' className='text-primary inline-block' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12' />
              </svg>
              {isUploading && <p className='ml-4'>Uploading...</p> }
              {!isUploading && (isDragActive ?
                <p className='ml-4'>Drop the files here ...</p> :
                <p className='text-gray-700 ml-4'>Drag 'n' drop an image here,<br />or click to select a file.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
