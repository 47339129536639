import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Layout from 'components/layout'

export default function Index() {
  useAuth()

  const [createdContacts, setCreatedContacts] = useState(false)
  const [updatedContacts, setUpdatedContacts] = useState(false)

  useEffect(() => {
    const unsubscribe = []
    setTimeout(() => {
      unsubscribe.push(
        db.collection('contacts')
          .orderBy('createdAt', 'asc')
          .limit(5)
          .onSnapshot(snapshot => {
            const contacts = []
            snapshot.forEach(doc => contacts.push(doc))
            setCreatedContacts(contacts)
          })
      )
      unsubscribe.push(
        db.collection('contacts')
          .orderBy('updatedAt', 'desc')
          .limit(5)
          .onSnapshot(snapshot => {
            const contacts = []
            snapshot.forEach(doc => contacts.push(doc))
            setUpdatedContacts(contacts)
          })
      )
    }, 990)
    return () => unsubscribe.forEach(u => u())
  }, [])

  return (
    <Layout>
      <main>
        <div className='py-32'>
          <div className='px-6 max-w-4xl m-auto'>
            <div className='bg-gray-900 rounded-xl mb-6 overflow-hidden'>
              <div className='relative fade-up'>
                <span className='block aspect-ratio-21/9' />
                <span className='absolute inset-0 flex h-full w-full'>
                  <img alt='Hero' src='./img/design/hero.png' />
                </span>
              </div>
            </div>
          </div>

          <div className='px-6 max-w-4xl m-auto'>
            <div className='bg-gray-900 rounded-xl p-4 mb-6'>
              <div className='grid sm:grid-cols-3 gap-4'>
                <Link
                  to={'/maps'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Maps</span>
                  </span>
                </Link>
                <Link
                  to={'/contacts'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Contacts</span>
                  </span>
                </Link>
                <Link
                  to={'/materials'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Materials</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className='grid sm:grid-cols-2 gap-6 px-6 max-w-4xl m-auto'>
            <List title='Recently Created Contacts' docs={createdContacts} />
            <List title='Recently Updated Contast' docs={updatedContacts} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

function List({ title, docs }) {
  return (
    <div className='bg-gray-900 rounded-xl'>
      <div className='p-4 pb-12'>{title}</div>
      <ul className='divide-y-2 divide-gray-800 text-sm'>
        {docs && docs.map((doc) => {
          let name = ''
          if(doc.data().firstName || doc.data().lastName) {
            if(doc.data().firstName) {
              name += doc.data().firstName + ' '
            }
            if(doc.data().lastName) {
              name += doc.data().lastName
            }
          } else {
            name += doc.data().email
          }
          return (
            <li className='fade-in' key={'list-'+doc.id}>
              <Link
                to={'/blog/'+doc.id}
                className='group px-4 py-2 block w-full text-left flex items-center focus:outline-none'>
                <span className='pr-4'>
                  <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-800 text-gray-700 flex'>
                    <svg xmlns='http://www.w3.org/2000/svg' className='m-auto h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z' clipRule='evenodd' />
                    </svg>
                  </span>
                </span>
                <span className='group-hover:text-primary transition duration-150 flex-1 truncate pr-4'>{name}</span>
                <span className='flex-1 truncate text-gray-600 text-right'>{doc.data().lifecycleStage ? doc.data().lifecycleStage.label : 'No Lifecycle'}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
