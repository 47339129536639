import { Link } from 'react-router-dom'
import Menu from 'components/menu'

export default function Layout({ children }) {
  return (
    <div className='min-h-screen flex flex-col'>
      <header>
        <div className='p-6 fixed top-0 left-0 z-10'>
          <Link
            className={'group border-2 border-current block h-16 w-16 duration-150 transform hover:scale-105 flex rounded-xl bg-gray-900 text-primary'}
            to='/'>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-2xl block transform scale-100 opacity-100 duration-150 group-hover:scale-0 group-hover:opacity-0'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                <path fillRule='evenodd' d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z' clipRule='evenodd' />
              </svg>
            </span>
            <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-xs block transform scale-0 opacity-0 duration-150 group-hover:scale-100 group-hover:opacity-100'>Ops</span>
          </Link>
        </div>
        <div className='p-6 fixed top-0 right-0 z-10'>
          <Menu />
        </div>
      </header>
      <div className='fade-in'>
        {children}
      </div>
    </div>
  )
}
